/* NOTE* あとで確認 */
.wrapper {
  overflow: hidden;
}
/* NOTE* あとで確認 */
/*.top-animation {
  overflow: hidden;
}*/
.top-animation.completed {
  display: none;
}
.top-contents {
    position: absolute;
    opacity: 0;
}

.container-policy,
.container-stance,
.container-works,
.container-contact,
.container-profile {
  display: none;
}
.container-policy.show,
.container-stance.show,
.container-works.show,
.container-contact.show,
.container-profile.show {
  display: flex;
  position: absolute;
  justify-content: space-between;
  z-index: 9999;
  animation-name: fadeIn;
  animation-duration: 1s;
  background-color: #FFF;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container-policy.show .main,
.container-stance.show .main,
.container-profile.show .main {
  animation-name: container-slide-in;
  animation-duration: 1.15s;
  animation-fill-mode: forwards;
    width: 0;
  /*transform: translateX(100vw);*/
}

@keyframes container-slide-in {
  0% {
    width: 0;
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
  }
  52.17% {
    width: 100%;
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
  }
  65.2135% {
    width: 100%;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
  }
  100% {
    width: 62%;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
  }
}
@media screen and (min-width: 1080px) {
  @keyframes container-slide-in {
    0% {
      width: 0;
      /*animation-timing-function: ease-in, cubic-bezier(.1, calc(1/0.68), .53, .47);*/
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
    }
    52.17% {
      width: 100%;
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
    }
    65.2135% {
      width: 100%;
      animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
    }
    100% {
      width: calc(100vw - 408px);
      animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
    }
  }
}
.container-policy.show .side,
.container-profile.show .side,
.container-stance.show .side {
  animation-name: container-slide-in-side;
  animation-duration: .5s;
  animation-delay: 1.4s;
  animation-fill-mode: forwards;
  width: 0;
  padding: 0;
  /*transform: translateX(100vw);*/
}

@keyframes container-slide-in-side {
  0% {
    width: 0;
    padding: 0
  }
  100% {
    width: 38%;
    padding: calc(28*(100vw/1080)) calc(24*(100vw/1080));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes container-slide-in-side {
    0% {
      width: 0;
      padding: 0
    }
    100% {
      width: 408px;
      padding: 28px 24px;
    }
  }
}

.container-policy.show .side .sideLogo,
.container-profile.show .side .sideLogo,
.container-stance.show .side .sideLogo {
  animation-name: fadeIn;
  animation-duration: .5s;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.container__top .mainClose,
.container-policy.show .main .mainClose,
.container-profile.show .main .mainClose,
.container-stance.show .main .mainClose,
.container-works.show .main .mainClose,
.container-contact.show .main .mainClose {
  animation-name: fadeIn;
  animation-duration: .7s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.container__top .mainClose {
  animation-delay: 0s;
}
.container__top .mainClose span,
.container-policy.show .main .mainClose span,
.container-profile.show .main .mainClose span,
.container-stance.show .main .mainClose span,
.container-works.show .main .mainClose span,
.container-contact.show .main .mainClose span {
  animation-duration: .7s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
  transform: rotate(0);
}

.container__top .mainClose span{
  animation-duration: .7s;
  animation-delay: 0s;
}

.container__top .mainClose span:first-of-type, 
.container-policy.show .main .mainClose span:first-of-type,
.container-profile.show .main .mainClose span:first-of-type,
.container-stance.show .main .mainClose span:first-of-type,
.container-works.show .main .mainClose span:first-of-type,
.container-contact.show .main .mainClose span:first-of-type {
  animation-name: closeFirst;
}
.container__top .mainClose span:last-of-type,
.container-policy.show .main .mainClose span:last-of-type,
.container-profile.show .main .mainClose span:last-of-type,
.container-stance.show .main .mainClose span:last-of-type,
.container-works.show .main .mainClose span:last-of-type,
.container-contact.show .main .mainClose span:last-of-type {
  animation-name: closeLast;
}
@keyframes closeFirst {
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes closeLast {
  100% {
    transform: rotate(45deg);
  }
}


.container-policy.show .sideCopy,
.container-policy.show .main .mainHead,
.container-policy.show .main .policyHead,
.container-policy.show .main .policyDesc,
.container-policy.show .main .mainClose,
.container-profile.show .sideCopy,
.container-profile.show .main .mainHead,
.container-profile.show .main .profileBlock,
.container-profile.show .main footer,
.container-stance.show .sideCopy,
.container-stance.show .main .mainHead,
.container-stance.show .main .stanceHead,
.container-stance.show .main .stanceDesc {
  animation-name: fadeIn;
  animation-duration: 1.1s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.container-policy.show .side .sideCatch__policy,
.container-profile.show .side .sideCatch__profile,
.container-stance.show .side .sideCatch__stance {
  animation-name: container-slide-in-fadeUp;
  animation-duration: 1.1s;
  animation-delay: 1.9s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes container-slide-in-fadeUp {
  0% {
    opacity: 0;
    transform: translateY(calc(100vh * 0.1));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container__col1.show .mainLogo,
.container__col1.show .mainClose {
  animation-name: fadeIn;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.container__col1.show .mainHead {
  animation-name: container-down-in-fade;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.container__col1.show .worksBlock,
.container__col1.show .worksSub, 
.container__col1.show .copy,
.container__col1.show .subHead, 
.container__col1.show .contactBlock,
.container__col1.show .copy {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}


/* 戻り*/

.container-policy.show.hide .sideCopy,
.container-policy.show.hide .main .mainHead,
.container-policy.show.hide .main .policyHead,
.container-policy.show.hide .main .policyDesc,
.container-policy.show.hide .main .mainClose,
.container-profile.show.hide .sideCopy,
.container-profile.show.hide .main .mainHead,
.container-profile.show.hide .main .profileBlock,
.container-profile.show.hide .main footer,
.container-profile.show.hide .main .mainClose,
.container-stance.show.hide .sideCopy,
.container-stance.show.hide .main .mainHead,
.container-stance.show.hide .main .stanceHead,
.container-stance.show.hide .main .stanceDesc,
.container-stance.show.hide .main .mainClose {
  animation-name: fadeOut;
  animation-duration: .7s;
  animation-delay: 0s;
  /*animation-delay: 2.4s;*/
  animation-fill-mode: forwards;
  opacity: 1;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container__top.hide .mainClose span,
.container-policy.show.hide .main .mainClose span,
.container-profile.show.hide .main .mainClose span,
.container-stance.show.hide .main .mainClose span {
  animation-duration: .7s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}
.container-works.show.hide .main .mainClose span,
.container-contact.show.hide .main .mainClose span {
  animation-duration: .7s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.container__top.hide .mainClose span:first-of-type,
.container-policy.show.hide .main .mainClose span:first-of-type,
.container-profile.show.hide .main .mainClose span:first-of-type,
.container-stance.show.hide .main .mainClose span:first-of-type,
.container-works.show.hide .main .mainClose span:first-of-type,
.container-contact.show.hide .main .mainClose span:first-of-type {
  animation-name: closeFirstHide;
  transform: rotate(-45deg);
}
.container__top.hide .mainClose span:last-of-type,
.container-policy.show.hide .main .mainClose span:last-of-type,
.container-profile.show.hide .main .mainClose span:last-of-type,
.container-stance.show.hide .main .mainClose span:last-of-type,
.container-works.show.hide .main .mainClose span:last-of-type,
.container-contact.show.hide .main .mainClose span:last-of-type {
  animation-name: closeLastHide;
  transform: rotate(45deg);
}
@keyframes closeFirstHide {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    opacity: 0;
    transform: rotate(0deg);
  }
}
@keyframes closeLastHide {
  0% {
    transform: rotate(45deg);
  }
  100% {
    opacity: 0;
    transform: rotate(0);
  }
}






.container-policy.show.hide .side .sideCatch__policy,
.container-profile.show.hide .side .sideCatch__profile,
.container-stance.show.hide .side .sideCatch__stance {
  animation-name: container-slide-in-fadeUp-hide;
  animation-duration: .7s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
  opacity: 1;
}
@keyframes container-slide-in-fadeUp-hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(calc(100vh * 0.1));
  }
}

.container-policy.show.hide .side .sideLogo,
.container-profile.show.hide .side .sideLogo,
.container-stance.show.hide .side .sideLogo {
  animation-name: fadeOut;
  animation-duration: .5s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  opacity: 1;
}

.container-policy.show.hide .side,
.container-profile.show.hide .side,
.container-stance.show.hide .side {
  animation-name: container-slide-in-side-hide;
  animation-duration: .2s;
  animation-delay: 2.0s;
  width: 38%;
  padding: calc(28*(100vw/1080)) calc(24*(100vw/1080));
}

@keyframes container-slide-in-side-hide {
  0% {
    width: 38%;
    padding: calc(28*(100vw/1080)) calc(24*(100vw/1080));
  }
  100% {
    width: 0;
    padding: 0
  }
}
@media screen and (min-width: 1080px) {
  .container-policy.show.hide .side,
  .container-profile.show.hide .side,
  .container-stance.show.hide .side {
      width: 408px;
      padding: 28px 24px;
  }
  @keyframes container-slide-in-side-hide {
    0% {
      width: 408px;
      padding: 28px 24px;
    }
    100% {
      width: 0; padding: 0
    }
  }
}


.container-policy.show.hide .main,
.container-profile.show.hide .main,
.container-stance.show.hide .main {
  animation-name: container-slide-in-hide;
  animation-duration: 1.15s;
  animation-delay: 2.0s;
  animation-fill-mode: forwards;
  width: 62%;
  overflow: hidden;
  /*transform: translateX(100vw);*/
}


@keyframes container-slide-in-hide {
  0% {
    width: 62%;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
  }
  34.7865% {
    width: 100%;
    animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
  }
  47.85% {
    width: 100%;
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
  }
  100% {
    padding: 0;
    width: 0;
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
  }
}

@media screen and (min-width: 1080px) {
  .container-policy.show.hide .main,
  .container-profile.show.hide .main,
  .container-stance.show.hide .main {
      width: calc(100vw - 408px);
  }
  @keyframes container-slide-in-hide {
    0% {
      width: calc(100vw - 408px);
      animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
    }
    34.7865% {
      width: 100%;
      animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);/*easeOutCubic*/
    }
    47.85% {
      width: 100%;
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
    }
    100% {
      width: 0;
      padding: 0;
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0),;/*easeInCubic*/
    }
  }
}

.container-policy.show.hide,
.container-profile.show.hide,
.container-stance.show.hide {
  animation-name: fadeOut;
  animation-duration: .5s;
  animation-delay: 3.0s;
}


.container__col1.show.hide .mainLogo,
.container__col1.show.hide .mainClose {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  opacity: 1;
}
@keyframes container-down-in-fade {
  0% {
    opacity: 0;
    transform: translateY(calc(-24*(100vw/1080)));
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 1080px) {
  @keyframes container-down-in-fade {
    0% {
      opacity: 0;
      transform: translateY(-24px);
    }
    100% {
      opacity: 1;
    }
  }
}

.container__col1.show.hide .worksBlock,
.container__col1.show.hide .worksSub, 
.container__col1.show.hide .copy,
.container__col1.show.hide .subHead, 
.container__col1.show.hide .contactBlock,
.container__col1.show.hide .copy {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-delay: 0s;
  opacity: 1;
}

.container__col1.show.hide .mainHead {
  animation-name: container-down-in-fade-hide;
  animation-duration: 1s;
  animation-delay: 1s;
  opacity: 1;
}
@keyframes container-down-in-fade-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(calc(-24*(100vw/1080)));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes container-down-in-fade-hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(-24px);
    }
  }
}

/* 戻り*/





.side .sideCopy { 
}

/* top text animation {{{ */
.top-animation-text {
  display: none;
  overflow: hidden;
}
.top-animation-text.play {
  display: inherit;
}

/* note: 後で消す */
.skipAnimation {
  position: absolute;
  bottom: 40px;
  left: 80px;
}
/* Phrase A */
.indexPhrase--A {
    opacity: 0;
    animation-name: top-text-animation1;
    animation-duration: 2.7s;
    position: absolute;
}
@keyframes top-text-animation1 {
  0% {
    opacity: 0;
    top: calc(50% - (130/2 *(100vw/1080)));
    animation-timing-function: ease-in;
  }
  48.15% {
    opacity: 1;
    top: calc(50% - (130/2 *(100vw/1080)));
  }
  74.10% {
    opacity: 1;
    top: calc(50% - (130/2 *(100vw/1080)));
  }
  100% {
    opacity: 0;
    top: calc(50% - (130/2 * 3 *(100vw/1080)));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes top-text-animation1 {
    0% {
      opacity: 0;
      top: calc(50% - (130px/2));

    }
    70% {
      opacity: 1;
      top: calc(50% - (130px/2));
    }
    100% {
      opacity: 0;
      top: calc(50% - (130px/2 * 3));
    }
  }
}

/* Phrase B2 */
.indexPhrase--B1 {
    opacity: 0;
    animation-name: top-text-animationB1;
    animation-duration: 1.0s;
    animation-delay: 2.8s;
}
.indexPhrase--B2 {
    opacity: 0;
    animation-name: top-text-animationB2;
    animation-duration: 1.0s;
    animation-delay: 2.8s;
}
@keyframes top-text-animationB1 {
  0% {
    opacity: 1;
    transform: TranslateX(100px);
  }
  40% {
    opacity: 1;
    transform: TranslateX(0);
  }
  100% {
    opacity: 1;
    transform: TranslateX(0);
  }
}
@keyframes top-text-animationB2 {
  0% {
    opacity: 1;
    transform: TranslateX(-100px);
  }
  40% {
    opacity: 1;
    transform: TranslateX(0);
  }
  100% {
    opacity: 1;
    transform: TranslateX(0);
  }
}
.indexPhrase--B3 {
    opacity: 0;
    animation-name: top-text-animationB3;
    animation-duration: 1.1s;
    animation-delay: 2.7s;
    animation-timing-function: ease-out;
}
@keyframes top-text-animationB3 {
  0% {
    opacity: 0;
    transform: scale(2.4);
  }
  39% {
    opacity: 0;
    transform: scale(2.4);
  }
  40% {
    opacity: 0.1;
    transform: scale(2.4);
  }
  88% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Phrase C2 */
.indexPhrase--C1 {/*shadow*/
    opacity:0;
    animation-name: top-text-animation15;
    animation-duration: .5s;
    animation-delay: 3.8s;
}
@keyframes top-text-animation15 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(calc(15px / 1.414), calc(-15px / 1.414));
  }
}
.indexPhrase--C2 {
    opacity: 0;
    animation-name: top-text-animation16;
    animation-duration: .5s;
    animation-delay: 3.8s;
    position: absolute;
}
@keyframes top-text-animation16 {
  0% {
    opacity:1;
  }
  100% {
    opacity: 1;
    transform: translate(calc(-15px / 1.414), calc(-15px / 1.414));
  }
}




/* Phrase D2 */
.indexPhrase--D1 {/*shadow*/
    opacity: 0;
    animation-name: top-text-animation6;
    animation-duration: .5s;
    animation-delay: 4.3s;
    position: absolute;
}
@keyframes top-text-animation6 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(calc(-15px / 1.414), calc(-15px / 1.414));
  }
}
.indexPhrase--D2 {
    opacity: 0;
    animation-name: showContent;
    animation-duration: .5s;
    animation-delay: 4.3s;
    position: absolute;
}
@keyframes showContent {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

/* Phrase E2 */
.indexPhrase--E1 {
    opacity: 0;
    animation-name: top-text-animationE1;
    animation-duration: 1.0s;
    animation-delay: 4.9s;
}
.indexPhrase--E2 {
    opacity: 0;
    animation-name: top-text-animationE2;
    animation-duration: 1.0s;
    animation-delay: 4.9s;
}
.indexPhrase--E3 {
    opacity: 0;
    animation-name: top-text-animationB3;
    animation-duration: 1.0s;
    animation-delay: 4.9s;
}
@keyframes top-text-animationE1 {
  0% {
    opacity: 1;
    transform: translateY(114px);
  }
  40% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes top-text-animationE2 {
  0% {
    opacity: 1;
    transform: translateY(-88px);
  }
  40% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Phrase F2 */
.indexPhrase--F1 {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 0.8s;
    animation-delay: 5.9s;
    position: absolute;
}
.indexPhrase--F2 {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.8s;
    animation-delay: 5.9s;
    position: absolute;
}

/* Phrase H2 */
.indexPhrase--H1 {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 0.9s;
    animation-delay: 6.7s;
    position: absolute;
}

.indexPhrase--H2 {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 0.9s;
    animation-delay: 6.7s;
    position: absolute;
}

/* Phrase J2 */
.indexPhrase--J1 {
    opacity: 0;
    animation-name: top-text-animation12;
    animation-duration: 2.4s;
    animation-delay: 7.6s;
    position: absolute;
}
@keyframes top-text-animation12 {
  0% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  83% {
    opacity: 1;
    left: calc(50% - (321/2 *(100vw/780)))
  }
  100% {
    opacity: 1;
    left: calc(50% - (321/2 *(100vw/780)))
  }
}
.indexPhrase--J2 {
    opacity: 0;
    animation-name: top-text-animation13;
    animation-duration: 2.4s;
    animation-delay: 7.6s;
    position: absolute;
}
@keyframes top-text-animation13 {
  0% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  83% {
    opacity: 1;
    right: calc(50% - (321/2 *(100vw/780)))
  }
  100% {
    opacity: 1;
    right: calc(50% - (321/2 *(100vw/780)))
  }
}

/* }}} top text animation */

/* top logo animation {{{ */
.top-animation-logo {
  display: none;
}
.top-animation-logo img {
  width: 100%;
  height: auto;
}
.top-animation-logo.play {
  display: inherit;
}

.indexLogo--c {
    opacity: 0;
    animation-name: top-logo-animation1;
    animation-duration: 8.3s;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation1 {
  0% {
    opacity: 0;
    left: calc(50% - (89 / 2 * (100vw/1080)));
  }
  3.85% {
    opacity: 1;
    left: calc(50% - (89 / 2 * (100vw/1080)));
  }
  7.7% {
    opacity: 1;
    left: calc(50% - (89 / 2 * (100vw/1080)));
  }
  11.55% {
    opacity: 0;
    left: calc(50% - (89 / 2 * (100vw/1080)));
  }
  42.35% {
    opacity: 0;
    left: calc(50% - (89 / 2 * (100vw/1080)));
  }
  70% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.indexLogo--r {
    opacity: 0;
    animation-name: top-logo-animation2;
    animation-duration: 8.3s;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation2 {
  0% {
    opacity: 0;
    left: calc(50% - (58 / 2 * (100vw/1080)));
  }
  11.55% {
    opacity: 0;
    left: calc(50% - (58 / 2 * (100vw/1080)));
  }
  15.4% {
    opacity: 1;
    left: calc(50% - (58 / 2 * (100vw/1080)));
  }
  19.25% {
    opacity: 0;
    left: calc(50% - (58 / 2 * (100vw/1080)));
  }
  42.35% {
    opacity: 0;
    left: calc(50% - (58 / 2 * (100vw/1080)));
  }
  70% {
    opacity: 1;
    left: calc(146*(100vw/1080));
  }
  100% {
    opacity: 1;
    left: calc(146*(100vw/1080));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes top-logo-animation2 {
    0% {
      opacity: 0;
      left: calc(50% - (58 / 2 * (100vw/1080)));
    }
    11.55% {
      opacity: 0;
      left: calc(50% - (58 / 2 * (100vw/1080)));
    }
    15.4% {
      opacity: 1;
      left: calc(50% - (58 / 2 * (100vw/1080)));
    }
    19.25% {
      opacity: 0;
      left: calc(50% - (58 / 2 * (100vw/1080)));
    }
    42.35% {
      opacity: 0;
      left: calc(50% - (58 / 2 * (100vw/1080)));
    }
    70% {
      opacity: 1;
      left: 146px;
    }
    100% {
      opacity: 1;
      left: 146px;
    }
  }
}


.indexLogo--e {
    opacity: 0;
    animation-name: top-logo-animation3;
    animation-duration: 8.3s;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation3 {
  0% {
    opacity: 0;
    left: calc(50% - (29 / 2 * (100vw/1080)));
  }
  19.25% {
    opacity: 0;
    left: calc(50% - (29 / 2 * (100vw/1080)));
  }
  23.1% {
    opacity: 1;
    left: calc(50% - (29 / 2 * (100vw/1080)));
  }
  26.95% {
    opacity: 0;
    left: calc(50% - (29 / 2 * (100vw/1080)));
  }
  42.35% {
    opacity: 0;
    left: calc(50% - (29 / 2 * (100vw/1080)));
  }
  70% {
    opacity: 1;
    left: calc(260*(100vw/1080));
  }
  100% {
    opacity: 1;
    left: calc(260*(100vw/1080));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes top-logo-animation3 {
    0% {
      opacity: 0;
      left: calc(50% - (29 / 2 * (100vw/1080)));
    }
    19.25% {
      opacity: 0;
      left: calc(50% - (29 / 2 * (100vw/1080)));
    }
    23.1% {
      opacity: 1;
      left: calc(50% - (29 / 2 * (100vw/1080)));
    }
    26.95% {
      opacity: 0;
      left: calc(50% - (29 / 2 * (100vw/1080)));
    }
    42.35% {
      opacity: 0;
      left: calc(50% - (29 / 2 * (100vw/1080)));
    }
    70% {
      opacity: 1;
      left: 260px;
    }
    100% {
      opacity: 1;
      left: 260px;
    }
  }
}

.indexLogo--h {
    opacity: 0;
    animation-name: top-logo-animation4;
    animation-duration: 8.3s;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation4 {
  26.95% {
    opacity: 0;
    left: calc(50% - (45 / 2 * (100vw/1080)));
  }
  30.8% {
    opacity: 1;
    left: calc(50% - (45 / 2 * (100vw/1080)));
  }
  34.65% {
    opacity: 0;
    left: calc(50% - (45 / 2 * (100vw/1080)));
  }
  42.35% {
    opacity: 0;
    left: calc(50% - (45 / 2 * (100vw/1080)));
  }
  70% {
    opacity: 1;
    left: calc(362*(100vw/1080));
  }
  100% {
    opacity: 1;
    left: calc(362*(100vw/1080));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes top-logo-animation4 {
    26.95% {
      opacity: 0;
      left: calc(50% - (45 / 2 * (100vw/1080)));
    }
    30.8% {
      opacity: 1;
      left: calc(50% - (45 / 2 * (100vw/1080)));
    }
    34.65% {
      opacity: 0;
      left: calc(50% - (45 / 2 * (100vw/1080)));
    }
    42.35% {
      opacity: 0;
      left: calc(50% - (45 / 2 * (100vw/1080)));
    }
    70% {
      opacity: 1;
      left: 362px;
    }
    100% {
      opacity: 1;
      left: 362px;
    }
  }
}
.indexLogo--a {
    opacity: 0;
    animation-name: top-logo-animation5;
    animation-duration: 8.3s;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation5 {
  34.65% {
    opacity: 0;
    left: calc(50% - (48 / 2 * (100vw/1080)));
  }
  38.5% {
    opacity: 1;
    left: calc(50% - (48 / 2 * (100vw/1080)));
  }
  42.35% {
    opacity: 1;
    left: calc(50% - (48 / 2 * (100vw/1080)));
  }
  70% {
    opacity: 1;
    left: calc(453*(100vw/1080));
  }
  100% {
    opacity: 1;
    left: calc(453*(100vw/1080));
  }
}
@media screen and (min-width: 1080px) {
  @keyframes top-logo-animation5 {
    34.65% {
      opacity: 0;
      left: calc(50% - (48 / 2 * (100vw/1080)));
    }
    38.5% {
      opacity: 1;
      left: calc(50% - (48 / 2 * (100vw/1080)));
    }
    42.35% {
      opacity: 1;
      left: calc(50% - (48 / 2 * (100vw/1080)));
    }
    70% {
      opacity: 1;
      left: 451px;
    }
    100% {
      opacity: 1;
      left: 451px;
    }
  }
}
.indexLogo--design {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-delay: 5.3s;
    animation-fill-mode: forwards;
}
.indexLogo--creation {
    opacity: 0;
    animation-name: top-logo-animation7;
    animation-duration: 1s;
    animation-delay: 5.3s;
    animation-fill-mode: forwards;
}
@keyframes top-logo-animation7 {
  0% {
    opacity: 0;
    transform: translateY(calc(100vh * 0.1));
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.indexLogo.complete .indexLogo--c,
.indexLogo.complete .indexLogo--r,
.indexLogo.complete .indexLogo--e,
.indexLogo.complete .indexLogo--h,
.indexLogo.complete .indexLogo--a,
.indexLogo.complete .indexLogo--design,
.indexLogo.complete .indexLogo--creation {
    opacity: 1;
    animation-name: none;
    animation-fill-mode: forwards;
}
/* }}} top logo animation */


.container__top.hide .indexLogo.complete {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.top-menu {
    opacity: 0;
    display: none;
}
.top-menu.show,
.top-menu.hide {
  display: inherit;
}
.top-menu.show {
  opacity: 0;
    animation-name: fadeIn;
    animation-delay: -0.2s;
    animation-fill-mode: forwards;
}
.top-menu.hide {
  opacity: 1;
  animation-delay: .5s;
}
.top-menu.show li {
    opacity: 0;
    animation-name: topmenushow;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}
.top-menu.hide  li{
    animation-name: topmenuhide;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
    opacity: 1;
}
.top-menu.show li.menu--policy,
.top-menu.show.hide li.menu--column {
    animation-delay: 0s;
}
.top-menu.show li.menu--stance,
.top-menu.show.hide li.menu--contact {
    animation-delay: 0.1s;
}
.top-menu.show li.menu--profile,
.top-menu.show.hide li.menu--works {
    animation-delay: 0.2s;
}
.top-menu.show li.menu--works,
.top-menu.show.hide li.menu--profile {
    animation-delay: 0.3s;
}
.top-menu.show li.menu--contact,
.top-menu.show.hide li.menu--stance {
    animation-delay: 0.4s;
}
.top-menu.show li.menu--column,
.top-menu.show.hide li.menu--policy {
    animation-delay: 0.5s;
}


@keyframes topmenushow {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.5;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes topmenuhide {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.gotop {
  opacity: 0;
}
.gotop.show {
  opacity: 1;
  animation-duration: .4s;
  animation-name: fadeIn;
  z-index: 9999;
}
